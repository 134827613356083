<template>
  <div id="app" class="flex-wrap flex text-base">
    <div class="w-full md:w-1/4"></div>
    <div class="w-full md:w-2/4 py-10">
      <div class="flex flex-wrap ">
        <div v-on:click="reloadPage" width="130px" class="p-4 cursor-pointer mr-4 bg-green-300
        border-b-8 border-green-600" >D O M O O D</div>
        <span class="pt-5">Can sıkıntısında ne yapacağını bilemeyenlere...</span>
      </div>
      <AnaSayfa/>
    </div>
  </div>
</template>

<script>
import AnaSayfa from './components/AnaSayfa.vue'

export default {
  name: 'App',
  components: {
    AnaSayfa
  },
  methods: {
    reloadPage(){
      window.location.reload()
    }
  }
}
</script>
