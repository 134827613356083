<template>
  <div class="hello">

    <div class="flex py-10 ">

      <div class="w-11/12 border-b-8 border-gray-600">
        <div class="p-4 bg-gray-200 border-4 border-b-0">
          <div class="font-bold text-xl text-gray-800 ">{{secilenMood.mood}}</div>
        </div>
      </div>
      <div class="w-1/12 border-b-8 border-blue-900">
        <a class="text-white text-center block text-lg h-full outline-none cursor-pointer
        pt-5 select-none bg-blue-600" v-on:click="moodSec">ef5</a>
      </div>

    </div>
    <div class="flex mb-10">
      <div class="w-11/12">
        <input type="text" alt="İsim" placeholder="İsim" class="border w-full mb-2 p-2"><br>
        <input type="text" alt="Mood" placeholder="Mood" class="border w-full p-2">
      </div>
      <div class="w-1/12"><button>Yolla Baba</button></div>
    </div>

    <div class="italic">
      <div>Domood © 2021</div>
      <button class="text-blue-800 outline-none border-0" v-on:click="gizliMi = !gizliMi">Olay Nedir?</button>
      <div v-if="!gizliMi" class="py-5 text-gray-800">
        Hacı şimdi olay oldukça basit. mood kısaca canın sıkıldığında, "ulan acaba..." dediğin şey oluyor. ama şimdi
        mesela senin aklına halk arasında söylemek istemeyeceğin(en azından ayık kafayla) birşey geldi, o da mood oluyor
        ama biz onu yayınlamıyoruz, sen de yayınlama bizce. Yayımlanmayı hakeden bir mood olması için azcık hayal gücü,
        biraz espri katıp yumurta ile çalkalamanız gerekiyor. Sonra yemiyorsunuz. Bize yolluyorsunuz. Biz de
        başkalarıyla paylaşıyoruz. okuyan kazanıyor, okutan kazanıyor, türkiye kazanıyor.. sanki hala anlamadın gibiyse;
        Mood o gri kutunun içinde yazanlar. Sen de yaz yolla. Arasıra da olsa "şu an çok mutsuzum" , " ben küçükken
        topumuz inşaata kaçtı" gibi şeyler yazmayın. Ya da yazın açılırsınız belki ne biliyim. Ama yayımlamayacağız yani.
      </div>
      <div class="border-t-2 mt-2 py-2">
        Sitede yazılanlar yanlızca mood gönderenlerin fantezilerini süslemektedir.
        Yapılması veya yapılmaması zorunlu değildir. Can sıkıntısında ne yapacağını bilemeyenler,
        öğrenmek istememekte özgürdürler. Site yönetimi ve site yapılanların hiç biriyle ilgili kanunen sorumlu tutulamaz.
      </div>
    </div>

  </div>
</template>

<script>
import moodlar from '../assets/moodlar.json'

export default {
  name: 'AnaSayfa',
  data(){
    return{
      gizliMi: true,
      secilenMood: String("Mood seçiliyor"),
    }
  },
  methods: {
    moodSec: function () {
      var toplamMoodSayisi =  Object.keys(moodlar).length;
      var i = this.getRndInteger(0,toplamMoodSayisi);
      console.log(i);
      this.secilenMood = moodlar[i];
    },
    getRndInteger: function(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    }
  },
  beforeMount(){
    this.moodSec()
  },
}
</script>
